@import 'styles/_import.scss';

.panel {
    position: fixed;
    width: rem(400);
    height: calc(100vh - rem(72));
    left: rem(-400);
    background: $color-black-70;
    transition: left 340ms linear;

    & .container {
        display: flex;
        flex-direction: column;
    }

    &.open {
        display: block;
        left: rem(68);
        // transition: left 340ms linear;
    }
}