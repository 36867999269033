@import 'styles/_import.scss';

.zoom-control__container {
  position: absolute;
  right: rem(22);
  bottom: rem(20);
  z-index: 1;
  transition: .5s;

  &.calendar-open {
    bottom: rem(206);
  }

  .-plus,
  .-minus {
    line-height: 0;
  }

  .-plus {
    .-plus__button {
      width: rem(28);
      height: rem(28);
      margin: 0;
      padding: 0;
      border: 1px solid #e3e3e3;
      background: #fff url('/assets/icon/icon-btn-plus.svg') center no-repeat;
      background-size: rem(12);
      border-radius: 3px 3px 0 0;
      cursor: pointer;

      &.disabled {
        background-color: #d8dadc;
      }
    }
  }

  .-bar {
    width: rem(26);
    min-height: 100px;
    background: #fff;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: rem(11) 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .-content {
      color: #333333;
      width: rem(16);
      border: 0;
      background: transparent url('/assets/images/zoom_background.png') top center repeat-y;
      background-size: rem(12) rem(9);
      height: rem(100);
      position: relative;
      text-align: left;
      font-size: 1em;

      .-range {
        width: rem(2);
        margin: 0 rem(7);
        border: 0;
        background-image: linear-gradient(-180deg, #0078d4 0%, #00ffff 100%);
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        background-position: 0 0;
      }

      .-handle {
        width: rem(12);
        height: rem(12);
        border: 2px solid #55595c;
        background: #fff;
        border-radius: 50%;
        outline: none;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: -rem(6);
        position: absolute;
        z-index: 2;
        margin-left: 0;
      }
    }
  }

  .-minus {
    .-minus__button {
      width: rem(28);
      height: rem(28);
      margin: 0;
      padding: 0;
      border: 1px solid #e3e3e3;
      background: #fff url('/assets/icon/icon-btn-minus.svg') center no-repeat;
      background-size: rem(12);
      border-radius: 0 0 rem(3) rem(3);
      cursor: pointer;

      &.disabled {
        background-color: #d8dadc;
      }
    }
  }
}