@import 'styles/_import.scss';

.overlay-container {
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-radius: 4px;
    background: #FFF;

    table {
        display: flex;
        gap: 2px;
        border-collapse: separate;
        border-spacing: 0;

        tr {
            display: grid;
            gap: 1px;

            th {
                display: flex;
                padding: 8px 10px;
                align-items: center;
                align-self: stretch;
                background: $color-bg-f1;
                color: $color-black-50;
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
            }

            td {
                display: flex;
                padding: 8px 0px 8px 10px;
                justify-content: space-between;
                align-items: center;
                gap: 2px;
                align-self: stretch;

                span {
                    color: $color-black-70;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.24px;
                    padding: 0px 10px 0px 0px;
                }

                .icon {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: url('/assets/icon/icon-btn-copy.svg') no-repeat 50% 50%;
                    cursor: pointer;
                }
            }
        }


    }
}