@import 'styles/_import.scss';

.header {
    width: auto;
    height: rem(72);
    display: flex;
    padding: rem(10) rem(20);
    box-sizing: border-box;
    justify-content: space-between;

    &.-basic {
        background-color: $color-black-100;

        & img {
            // width: rem(201);
            background-position: center;
        }

        & .logo {
            background: url('/assets/logo/logo-basic-dabeeo.svg') no-repeat;
            width: rem(201);
            background-position: center;
        }
    }
}