@import "styles/_import.scss";

.modal {
    position: absolute;
    width: rem(409);
    height: rem(236);
    padding: rem(12) rem(24) rem(28) rem(24);
    border: 1px solid $color-black-10;
    background: $color-white-100;
    box-shadow: 0px 3px 15px 0px rgba(153, 153, 153, 0.40);
    z-index: 21;
    top: rem(180);
    left: rem(320);
    display: flex;
    flex-direction: column;
    gap: rem(12);
    line-height: rem(36);

    & .header {
        height: rem(36);
        display: flex;
        gap: rem(8);

        & .-ico {
            align-self: center;
            display: inline-block;
            width: rem(18);
            height: rem(18);
            left: rem(8);
            top: rem(9);

            &.-filter {
                background: url('/assets/icon/icon-filter-black.svg') no-repeat;
                background-size: rem(10);
                background-position: center;
            }
        }

        & .-title {
            color: $color-black-90;
            font-weight: bold;
        }

        & .-count {
            align-self: center;
            padding: 0 rem(6);
            height: rem(18);
            line-height: rem(18);
            font-size: 12px;
            font-weight: 500;
            color: $color-white-100;
            background: $color-black-90;
            border-radius: rem(9);
        }

        & .button {
            position: absolute;
            align-self: center;
            padding: 0 rem(12);
            height: rem(24);
            border: 1px solid $color-black-40;
            border-radius: rem(3);
            background: $color-white-100;
            color: $color-black-90;
            right: rem(24);
            cursor: pointer;

            &:hover {
                border: 1px solid $color-black-100;
                color: $color-black-100;
            }

            &:active {
                border: 1px solid $color-black-40;
                color: $color-black-80;
            }
        }
    }

    & .options {
        display: flex;
        gap: rem(8);
    }
}