@import 'styles/_import.scss';

.fold-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/assets/icon/icon-btn-fold.svg') no-repeat 50% 50%;
    border-radius: 3px;
    border: 1px solid $color-black-30;
    cursor: pointer;
    box-sizing: border-box;

    &:not(.open) {
        background: url('/assets/icon/icon-btn-unfold.svg') no-repeat 50% 50% $color-black-100;
        border: none;
    }
}