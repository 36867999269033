@import '/src/styles/_import.scss';


.alert__container {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid $color-text-graycc;
    width: 440px;
    padding-bottom: 36px;

    .alert__close-wrap {
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 12px;

        .icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url('/assets/icon/icon-popup-close.svg') no-repeat 50% 50%;
            cursor: pointer;
        }
    }

    .alert__message-wrap {
        height: 80px;
        display: flex;
        justify-content: center;
        font-weight: 500;
        line-height: 22px;
        font-size: 14px;
        color: $color-black-70;
        white-space: pre-wrap;
    }

    .alert__button-wrap {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}