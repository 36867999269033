@import "styles/_import.scss";

.container {
    height: rem(38);
    display: flex;
    gap: rem(10);

    & .wrap {
        display: block;
        position: relative;

        input {
            width: rem(262);
            height: 100%;
            padding: rem(0) rem(12);
            border: 1px solid $color-black-60;
            background: $color-black-70;
            font-size: 14px;
            font-weight: 400;
            outline: none;
            box-shadow: none;
            color: $color-black-10;

            &:focus {
                background-color: $color-black-80;
                border: 1px solid $color-black-40;
            }

            &::placeholder {
                color: $color-black-60;
            }
        }

        .clear {
            cursor: pointer;
            position: absolute;
            right: rem(12);
            top: rem(11);
            width: rem(18);
            height: rem(18);
            background: url('/assets/icon/icon-btn-clear.svg') no-repeat;
            background-position: center;
            background-size: rem(18);
        }
    }


    button {
        width: rem(80);
        background: $color-black-100;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        color: $color-black-10;
        cursor: pointer;
        border: none;

        &:hover {
            background-color: $color-black-90;
            color: $color-white-100;
        }

        &:active {
            background-color: $color-black-100;
            color: $color-black-40;
        }
    }


}