@import 'styles/_import.scss';


.swipe-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;

    .date__wrap {
        display: flex;
        box-sizing: border-box;
        overflow: hidden;
    }

    .date {
        display: flex;
        width: 100px;
        height: 32px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-black-80;
        background: rgba(255, 255, 255, 0.80);
        color: $color-black-100;
        font-size: 12px;
        font-weight: 500;
        box-sizing: border-box;
        flex-shrink: 0;
        margin: 16px 0px 0px 16px;
    }

    .input-range {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background: transparent;
        appearance: none;
        pointer-events: none;
        width: calc(100% - 4px);
        height: calc(100% - 4px);

        &::-webkit-slider-runnable-track {
            appearance: none;
            background: transparent;
            border: none;
            box-shadow: none;
        }

        &::-webkit-slider-thumb {
            appearance: none;
        }
    }

    .swipe-bar-wrap {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;


        .swipe-bar {

            background: #FFFFFF;
            width: 4px;
            height: 100%;
            border-left: 1px solid $color-black-90;
            border-right: 1px solid $color-black-90;
            position: absolute;

            transform: translate(-50%);
            cursor: ew-resize;
            pointer-events: all;

        }

        .swipe-handle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.60);
            position: absolute;
            top: 50%;

            transform: translate(-50%, calc(-50% - 36px));
            background: url('/assets/icon/icon-btn-change-handle.svg') no-repeat 50% 50% #FFFFFF;
            border: 1px solid $color-black-20;
            cursor: ew-resize;
            pointer-events: all;

            &:hover {
                border-color: $color-black-40;
                background: url('/assets/icon/icon-btn-change-handle-hover.svg') no-repeat 50% 50% #FFFFFF;
            }

            &:active {
                border-color: $color-black-40;
                background: url('/assets/icon/icon-btn-change-handle-pressed.svg') no-repeat 50% 50% $color-black-10;
            }
        }
    }




}