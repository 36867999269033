@import '/src/styles/_import.scss';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;


    &.blue {
        background-color: $color-blue-100;
        color: $color-text-grayea;

        &:hover {
            background-color: #5A92FF;
        }
    }

    &.black {
        background-color: $color-black-100;
        color: $color-black-10;

        &:hover {
            background-color: $color-black-90;
        }
    }

    &.white {
        background-color: #FFFFFF;
        border: 1px solid $color-black-80;
        color: $color-black-70;

        &:hover {
            border: 1px solid $color-black-100;
            color: $color-black-100;
        }
    }

    &.red {
        background-color: #FFFFFF;
        border: 1px solid $color-red-100;
        color: $color-red-100;

    }
}