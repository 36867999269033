@import 'styles/_import.scss';

.map-view-control__container {
  position: absolute;
  left: rem(82);
  // transition: left 340ms linear;
  bottom: rem(20);
  z-index: 1;
  margin: 0 0 4px 4px;
  padding: 0 rem(12);
  height: rem(22);
  line-height: rem(22);
  background: rgba(255, 255, 255, 0.7);
  border-radius: rem(10);
  // transition: .5s;


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      position: relative;
      height: rem(22);
    }


    .-info-1 {
      text-align: center;
      font-size: 8px;
      font-weight: bold;
      color: #3e3e3e;
      line-height: rem(18);
      padding-left: rem(10);
      padding-right: rem(10);
    }

    .-u-line {
      position: absolute;
      left: 0;
      right: 0;
      bottom: rem(5);
      height: rem(7);
      border: 2px solid #3e3e3e;
      border-top: 0;
    }

    .-cap-1 {
      width: rem(8);
    }

    .-info-2 {
      text-align: center;
      font-size: 8px;
      font-weight: bold;
      color: #3e3e3e;
      line-height: rem(18);
      padding-left: rem(10);
      padding-right: rem(10);
    }

    .-cap-2 {
      width: rem(10);
    }

    .-info-3 {
      min-width: rem(100);
      padding: 0 rem(8);
      text-align: center;
      font-size: 9px;
      font-family: 'Roboto';
      font-weight: bold;
      color: #3e3e3e;

      .__icon {
        margin-right: rem(4.75);
        display: inline-block;
        width: rem(8);
        height: rem(8);
        line-height: initial !important;
        text-align: center;
        vertical-align: middle;
        background: transparent url('/assets/icon/icon-location.svg') center no-repeat;
      }
    }

    .-info-4 {
      min-width: rem(50);
      text-align: center;
      font-size: 9px;
      font-family: 'Roboto';
      font-weight: bold;
      color: #3e3e3e;
    }
  }
}

.shift {
  left: rem(22);
  transition: left 340ms linear;
}