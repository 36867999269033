@import 'styles/_import.scss';

.container {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 21px;

    .input {
        border-radius: 4px;
        border: 1px solid $color-black-70;
        background-color: $color-black-90;
        font-size: 12px;
        color: $color-black-50;
        width: 45px;
        height: 24px;
        text-align: center;
        box-sizing: border-box;

        &:hover:not(:disabled) {
            border: 1px solid $color-black-60;
        }

        &:focus {
            border: 1px solid $color-black-50;
            color: $color-black-30;
            outline: 0;
        }

        &:disabled {
            background-color: $color-black-70;
        }
    }
}