@import 'styles/_import.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border-bottom: 2px solid $color-black-80;

    .title-area {
        display: flex;
        align-items: center;
        padding: 6px 0;

        .title {
            flex: 1;
            font-size: 14px;
            font-weight: 700;
            color: $color-black-10;
            text-align: start;
        }
    }

    .data-area {
        border-radius: 8px;
        padding: 20px;
        background-color: $color-black-80;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.folded {
            display: none;
        }

        .data-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            font-size: 13px;

            .title {
                color: $color-black-40;
                font-weight: 600;
                line-height: 16px;
            }

            .value {
                color: $color-black-20;
                line-height: 18px;
            }

            .data-sources {
                display: flex;
                flex-direction: column;
                gap: 8px;
                line-height: 18px;

                .data-source {
                    display: flex;
                    align-items: start;
                    gap: 10px;

                    .direction {
                        min-width: 60px;
                        color: $color-black-30;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: start;
                    }

                    .file {
                        text-align: start;
                        font-size: 13px;
                        color: $color-black-20;
                        overflow-wrap: anywhere;
                    }
                }


            }
        }
    }
}