@import 'styles/_import.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: rem(10);
    padding: rem(12) rem(12) rem(10) rem(12);    
    color: $color-black-30;    
    background-color: $color-black-60;
    & .chip-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem(8);
    }
    & .total-box {
        text-align: left;
        height: rem(36);
        font-size: 14px;
        font-weight: bold;
        line-height: rem(36);
    }
    & .count{
        color: $color-white-100;
    }
}