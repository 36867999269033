@import 'styles/_import.scss';

.container {
    padding: 16px 20px;
    border-radius: 8px;
    background-color: $color-black-80;
    display: flex;
    align-items: center;

    .title {
        width: 90px;
        padding: 3px 0;
        text-align: start;
        color: $color-black-20;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
    }
}