@import "styles/_import.scss";

.container {
    position: relative;
    height: rem(32);
    width: auto;
    background: $color-black-90;
    border-radius: rem(16);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: rem(32);
    align-self: center;
    padding: 0 rem(15) 0 rem(15);
    white-space: nowrap;

    &.standby {
        color: $color-black-60;
        background: $color-black-90;
    }

    &.Analyzing {
        color: $color-green-100;
        background: $color-black-80;
    }

    &.failed {
        color: $color-red-100;
        background: $color-black-80;
    }

    &.completed {
        padding: 0 rem(16) 0 rem(8);
        width: rem(51);
        color: $color-white-100;
        background: $color-blue-100;

        & .ico {
            position: absolute;
            display: inline-block;
            right: rem(4);
            top: rem(7);
            width: rem(18);
            height: rem(18);
            z-index: 20;

            &.result {
                background: url('/assets/icon/icon_arrow_calendar_right.svg') no-repeat;
                background-position: center;
                background-size: rem(18);
            }
        }
    }
}