.rc-slider {
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .rc-slider-step,
    .rc-slider-rail,
    .rc-slider-track {
        border-radius: 4px;
        height: 8px;
    }

    .rc-slider-step,
    .rc-slider-rail,
    .rc-slider-track,
    .rc-slider-handle {
        position: absolute;
    }

    .rc-slider-step,
    .rc-slider-rail {
        width: 100%;
    }

    .rc-slider-rail {
        background-color: #5E6878;

    }

    .rc-slider-track {
        background-color: #D3D6DA;
    }

    .rc-slider-handle {
        border: none;
        opacity: 1;
        width: 12px;
        height: 12px;
        background-color: #FFFFFF;
        border-radius: 50%;
        cursor: grab;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.40));
        user-select: none;
        touch-action: pan-x;
        border: none;

        &:active {
            cursor: grabbing;
        }

        &:focus,
        &:focus-visible {
            outline: 0;
        }
    }

    &.rc-slider-disabled {
        .rc-slider-rail {
            background-color: #474F60;
        }

        .rc-slider-track {
            background-color: #6C7789;
        }

        .rc-slider-handle {
            background-color: #989FAC;
            cursor: default;
        }
    }
}