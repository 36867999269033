$browser-content-size:16 !global;

/* color palette */

$color-blue-100: #4F74F9;
$color-green-100: #43CA92;
$color-red-100: #D94545;

$color-white-100: #FFFFFF;
$color-bg-f9: #F9F9F9;
$color-bg-f1: #F1F1F1;
$color-bg-blue: #F2F5FF;
$color-bg-green: #EBFAF4;
$color-dark-dimed: #4E586E;

$color-black-100: #1B1D21;
$color-black-90: #2E3441;
$color-black-80: #384051;
$color-black-70: #474F60;
$color-black-60: #5E6878;
$color-black-50: #6C7789;
$color-black-40: #828A9A;
$color-black-30: #989FAC;
$color-black-20: #B6BAC3;
$color-black-10: #D3D6DA;

$color-input-default: #F7F8F8;
$color-input-disabled: #EBEBEB;

$color-text-gray44: #444;
$color-text-gray99: #999;
$color-text-graycc: #ccc;
$color-text-grayea: #eaeaea;
$color-text-darkblue: #4C6CB7;
$color-text-darkpurple :#7C49AE;
$color-text-darkyello:#AE9149;