@import "styles/_import.scss";

.container {        
    font-size: 12px;
    font-weight: 500;    
    display: flex;
    flex-direction: row;    
    flex-wrap: wrap;
    gap: rem(10);
    & .badge {        
        display: inline-block;
        background: $color-black-90;
        border-radius: rem(4);
        padding: rem(4) rem(6);    
        height: rem(16);        
        &.change {
            color: $color-text-darkpurple;
        }
        &.object {
            color:$color-text-darkblue;
        }
        &.segmentation {
            color:$color-text-darkyello
        }
    }
}