@import '/src/styles/_import.scss';

.my-page__container {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid $color-text-graycc;
    width: 580px;

    .my-page__header-wrap {
        padding: 20px 12px 20px 40px;
        line-height: 20px;
        display: flex;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: 700;
            color: $color-black-90;
            width: 100%;
            text-align: start;
        }

        .icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url('/assets/icon/icon-popup-close.svg') no-repeat 50% 50%;
            cursor: pointer;

        }
    }

    .my-page__body-wrap {
        padding: 24px 36px 40px 40px;
        border-top: 1px solid $color-black-100;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title {
            display: flex;
            align-items: center;
            height: 36px;
            line-height: 20px;
            color: $color-black-70;
            font-weight: 700;
        }

        .item {
            display: flex;
            gap: 10px;
            height: 36px;
            padding: 0 20px;
            font-weight: 500;
            font-size: 14px;

            .label {
                min-width: 120px;
                display: flex;
                align-items: center;
                color: $color-black-40;
            }

            .value {
                display: flex;
                align-items: center;
                padding: 0 12px;
                background-color: $color-bg-f9;
                width: 100%;
                color: $color-black-70;
            }
        }
    }

    .my-page__footer-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        border-bottom: 1px solid $color-black-100;
        padding-bottom: 36px;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            box-shadow: none;
            border-radius: 0;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            width: 500px;
            height: 52px;

            &.btn-confirm {
                background-color: transparent;
                border: 1px solid $color-black-80;
                color: $color-black-70;

                &:hover {
                    border: 1px solid $color-black-100;
                    color: $color-black-100;
                }

                &:active {
                    border: 1px solid $color-black-40;
                    background-color: $color-bg-f1;
                    color: $color-black-40;
                }
            }
        }
    }
}