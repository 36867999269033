@import "styles/_import.scss";
.chip {
    display: flex;
    position: relative;
    height: rem(36);
    background: $color-black-100;
    border-radius: rem(18);
    font-size: 14px;
    font-weight: 400;
    color: $color-black-10;
    line-height: rem(36);
    padding: 0 rem(8) 0 rem(12);
    text-align: left;    
    gap: rem(4);
    & .ico {
        width:rem(18);
        height: rem(36);
        display: inline-block;

        &.close {
            background: url('/assets/icon/icon-btn-delete.svg') no-repeat;
            background-position: center;
            background-size: rem(18);
            &:hover {
                cursor: pointer;
            }
        }
    }
}