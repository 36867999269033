@import 'styles/_import.scss';

.container {
  display: flex;
  align-items: center;
  height: 47px;
  justify-content: space-between;
  border-bottom: 1px solid $color-black-70;

  .title-area {
    display: flex;
    align-items: center;
    padding: 0px 6px;
  }

  .count {
    overflow: hidden;
    color: $color-black-20;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    padding: 0px 10px;
    justify-content: flex-end;
  }
}