@import 'styles/_import.scss';

.container {
    display: flex;
    flex-direction: column;

    .titlebar {
        height: 60px;
        background-color: $color-black-90;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 24px 0 12px;

        .icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(/assets/icon/icon-btn-back.svg);
            cursor: pointer;
        }

        .title {
            color: $color-black-10;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
        }
    }

    .request-info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 24px;
        background-color: $color-black-80;
        align-items: flex-start;
        border-bottom: 1px solid $color-black-90;

        .title {
            color: $color-black-10;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -1.15px;
        }

        .date {
            color: $color-black-40;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .result-wrap {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 252px);

        .content-wrap {
            flex: 1;
            overflow-y: auto;
        }

        .footer-wrap {
            height: 60px;
            padding: 0 12px;
            background-color: $color-black-100;
            display: flex;
            align-items: center;

            .right {
                // TODO: 레이아웃 추후 수정
                flex: 1;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.loading {
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
}