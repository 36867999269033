@import 'styles/_import.scss';

.folder {
    display: inline-block;
    width: rem(18);
    background: url('/assets/icon/icon-folder-basic.svg') no-repeat;
    background-size: rem(18) rem(18);
    background-position: center;
}

.title {
    max-width: rem(300);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.arrow {
    position: absolute;
    display: inline-block;
    width: rem(18);
    height: rem(18);
    right: rem(24);
    top: calc(rem(30) - rem(9));

    &.-down {
        background: url('/assets/icon/icon-arrow-down.svg') no-repeat;
        background-position: center;
        background-size: rem(18);
    }

    &.-up {
        background: url('/assets/icon/icon-arrow-up.svg') no-repeat;
        background-position: center;
        background-size: rem(18);
    }
}

.header {
    width: auto;
    height: rem(60);
    display: flex;
    background: $color-black-90;
    color: $color-black-10;
    font-size: 16px;
    font-weight: 500;
    line-height: rem(60);
    text-align: left;
    padding: 0 rem(24);
    gap: rem(10);

    & .folder {
        line-height: rem(60);
    }

    &.open {
        background: $color-black-80;
    }
}

.dropdown {
    position: absolute;
    z-index: 25;
    width: rem(400);
    height: rem(60);
    max-height: rem(300);
    padding: unset;
    margin: unset;
    background: $color-black-90;
    overflow: auto;
    color: $color-black-10;
    font-size: 16px;
    font-weight: 500;
    line-height: rem(60);
    text-align: left;
    display: none;

    &.open {
        display: block;
        height: auto;
    }

    & li {
        padding: 0 rem(24);
        display: flex;
        flex-direction: row;
        gap: rem(10);
        max-width: rem(338);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.current {
            height: rem(60);
        }
    }

    & .item {
        height: rem(60);

        &:hover {
            background: $color-text-darkblue;
        }

        &.selected {
            color: $color-white-100;
            font-weight: bold;
        }
    }

    &::-webkit-scrollbar {
        display: block;
        z-index: 999;
        position: absolute;
        width: rem(14);
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        opacity: 0;
        height: 136px;
        width: 4px;
        background-color: $color-black-40;
        border-radius: 4px;
        border: 3px solid transparent;
        background-clip: padding-box;
        scrollbar-gutter: stable;
    }
}