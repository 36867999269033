@import 'styles/_import.scss';

.container {
    display: inline-block;
    width: calc(100vw - rem(68));
    height: calc(100vh - rem(72));
    overflow: hidden;
    // transition: left 340ms linear;
    left: rem(0);
    transition: transform 340ms linear, width 340ms linear;
    transform-origin: left;

    &.shift {
        position: relative;
        width: calc(100vw - rem(468));
        // left: rem(400);
        transform: translate(rem(400));
    }

    .map-slider-root {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 49;
        pointer-events: none;
    }
}