@import 'styles/_import.scss';

.container {    
    position: absolute;
    right: rem(12);
    width: rem(53);
    height: rem(36);        
    flex-direction: row;
    gap: rem(12);
    padding: 0 rem(12) 0 rem(8);    
    background: $color-black-70;
    line-height: rem(36);    
    text-align: right;
    border: 1px solid $color-black-10;
    & .ico {
        position: absolute;
        left: rem(8);
        top: rem(13);
        width: rem(12);
        height: rem(12);        
        &.filter {
            background: url('/assets/icon/icon-btn-filter-basic.svg') no-repeat;
            background-size: rem(12);
            background-position: center;
        }        
    }
    &:hover {
        border: 1px solid $color-white-100;
        > button {
            color: $color-white-100;
        }        
    }
    &:active {
        border: 1px solid $color-black-40;
        background-color: $color-black-90;
        > .ico {
            &.filter {
                background: url('/assets/icon/icon-btn-filter-active.svg') no-repeat;
            background-size: rem(12);
            background-position: center;
            }
        }
        > button {
            background-color: $color-black-90;
            color: $color-black-40;
        }    
    }
    & button {
        color: $color-black-10;
        font-size: 14px;
        font-weight: 500;
        padding: unset;
        background: $color-black-70;
        border: none;
    }
}