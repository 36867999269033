@import "styles/_import.scss";

.container {
    display: flex;
    flex-direction: column;
    padding: 0 rem(28) 0 rem(20);

    &:first-child,
    &:last-child {
        :first-child {
            padding-bottom: rem(8);
        }
    }

    gap: unset;

    &.-left {
        border-left: 1px solid $color-black-10;
    }

    &.-right {
        border-right: 1px solid $color-black-10;
    }
}