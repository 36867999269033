@import 'styles/_import.scss';

.container {
    padding: 8px 12px 16px;
    border-radius: 8px;
    background-color: $color-black-80;

    .header {
        display: flex;
        height: 36px;
        padding: 0 10px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid $color-black-50;

        span {
            color: $color-black-40;
            font-size: 14px;
            font-weight: 700;

            &.total {
                color: $color-blue-100;
            }
        }

        // .checkbox {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     min-width: 28px;
        // }

        // .name {
        //     flex: 1;
        //     text-align: start;
        // }

        // .result {
        //     min-width: 110px;
        //     text-align: right;
        //     padding-right: 10px;
        // }
    }
}