@import 'styles/_import.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border-bottom: 2px solid $color-black-80;

    .title-area {
        display: flex;
        align-items: center;
        padding: 6px 0;

        .title {
            flex: 1;
            display: flex;
        }

        .count {
            color: $color-black-20;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            padding: 0 10px 0 0;
        }
    }

    .folded {
        display: none;
    }

}