@import "styles/_import.scss";

.react-datepicker__input-container input {
    color: $color-white-100;
}

.container {
    width: auto;
    position: relative;
    text-align: left;
    cursor: pointer;

    svg {
        display: none;
    }

    & .ico {
        position: absolute;
        display: inline-block;
        top: rem(8);
        right: rem(8);
        z-index: 20;
        width: rem(16);
        height: rem(16);
        padding: rem(2);

        &.-calendar {
            background: url('/assets/icon/icon-datepicker-calendar.svg') no-repeat;
            background-position: center;
            background-size: rem(14);
        }
    }
}

input[type='text'] {
    color: $color-white-100;
    caret-color: transparent;
}

button[type='button'] {
    &::after {
        background-color: $color-black-100;
    }
}

.btn {
    width: rem(24);
    height: rem(24);

    &.-left {
        background: url('/assets/icon/icon_arrow_calendar_left.svg') no-repeat;
        background-size: rem(24);
        background-position: center;
    }
}

.button_area {
    display: flex;

    .header-btn {
        width: rem(24);
        height: rem(24);
        border: none;

        &:hover {
            cursor: pointer;
        }

        &.-double-left {
            background: url('/assets/icon/icon_arrow_calendar_double_left.svg') no-repeat;
            background-size: rem(24);
            background-position: center;
        }

        &.-left {
            background: url('/assets/icon/icon_arrow_calendar_left.svg') no-repeat;
            background-size: rem(24);
            background-position: center;
        }

        &.-right {
            background: url('/assets/icon/icon_arrow_calendar_right.svg') no-repeat;
            background-size: rem(24);
            background-position: center;
        }

        &.-double-right {
            background: url('/assets/icon/icon_arrow_calendar_double_right.svg') no-repeat;
            background-size: rem(24);
            background-position: center;
        }
    }
}