@import "styles/_import.scss";

.container {
    display: flex;
    padding: rem(24) rem(12);
    gap: rem(12);
    flex-direction: column;
    & .filter-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}