body {
  margin: 0;
  font-family: "Pretendard, Pretendard Variable", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .react-datepicker {        
    .react-datepicker__day--outside-month {
      cursor: default;
      visibility: hidden;      
    }
    .react-datepicker__day--today {      
      color: #4F74F9;
    }
  }
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ol-overlaycontainer-stopevent {
  z-index: 1 !important;
}

