@import 'styles/_import.scss';

.list-box {
    overflow: hidden;    
    display: flex;
    flex-direction: column;
    height: calc(100% - rem(2));
    margin-left: rem(1);
    & .-list {
        overflow: auto;
        &.h-58 {
            height: calc(100% - rem(256));
        }        
        &.h-104 {
            height: calc(100% - rem(298));
        }
        &.h-148 {
            height: calc(100% - rem(340));
        }
        &.h-192 {
            height: calc(100% - rem(382));
        }
    
        & .placeholder {        
            position: relative;
            top:45%;
            text-align: center;        
            color:$color-black-50;
            font-weight: bold;
            font-size:14px;
        }    
    }
    
}