@import 'styles/_import.scss';

.container {    
    z-index: 2;    
    display: flex;
    flex-direction: column;
    width: rem(68);   
    height: calc(100vh - rem(72));
    background: $color-black-90;
    overflow: hidden;
    justify-content: space-between;
    border-right: 1px solid $color-black-100;
}