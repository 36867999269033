@import 'styles/_import.scss';
.menu {
    width: rem(68);
    height: rem(88);    
    display: flex;
    flex-direction: column;    
    gap: rem(4);
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    color: $color-black-10;
    background: $color-black-90;
    border-top: 1px solid $color-black-100;
    &:only-child {
        border-top:unset;
    }    
    &.active {
        background: $color-blue-100;
    }
    .ico {
        display: block;
        width: rem(36);
        height: rem(36);
        padding: rem(14.7) rem(6) 0 rem(6);
    }  
    .result {
        background: url('/assets/icon/icon-menu-result.svg') no-repeat;
        background-position: bottom;
        background-size: rem(36);
    }
    &.manager {
        display: inline-block;
        height: rem(68);
        gap: unset;        
        background: url('/assets/icon/icon-menu-goto-manager.svg') no-repeat;
        &:hover {
            background:url('/assets/icon/icon-menu-goto-manager-hover.svg') no-repeat;
        }
        background-position: center;
        background-size: rem(36);        
    }
    &.info {
        display: inline-block;
        height: rem(68);
        gap: unset;
        background: url('/assets/icon/icon-menu-user-info.svg') no-repeat;
        &:hover {
            background:url('/assets/icon/icon-menu-user-info-hover.svg') no-repeat;
        }
        background-position: center;
        background-size: rem(36);
    }
    &.logout{
        display: inline-block;
        height: rem(68);
        gap: unset;
        background: url('/assets/icon/icon-menu-logout.svg') no-repeat;
        &:hover {
            background:url('/assets/icon/icon-menu-logout-hover.svg') no-repeat;
        }
        background-position: center;
        background-size: rem(36);
    }
}