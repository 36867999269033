@import "styles/_import.scss";

.check-box-wrap {
    display: flex;    
    font-size: 14px;
    font-weight: 500;
    line-height: rem(36);
    color: $color-black-90;
    text-align: left;
    align-items: center;
    width: fit-content;
    .checkbox {
        position: relative;        
        appearance: none;
        width: rem(16);
        height: rem(16);
        border: 1.5px solid $color-black-60;    
        cursor: pointer;
        margin: 0 rem(10) 0 rem(26);
        &.all {
            margin-left: 0;
        }
        &:checked {
            // border: 1.5px solid $color-blue-100;
            background: url(/assets/icon/icon-check-blue.svg) no-repeat;        
            background-position: center;
            background-size: rem(16);
    
            &:disabled {
                border: 1.5px solid $color-black-20;
                background: url(/assets/icon/icon-check-gray.svg) no-repeat;            
                background-position: center;
                background-size: rem(16);
            }
        }
    
        &:disabled {
            border: 1.5px solid $color-black-20;
            background: $color-black-10;
    
            &+label {
                color: $color-black-20;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}
