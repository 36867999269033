@import "styles/_import.scss";

.container {
    padding: rem(24) rem(16) rem(24)rem(24);    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: rem(16);
    border-bottom: 1px solid $color-black-60;

    &.selected {        
        background: #4b557d;
        border: 1px solid $color-blue-100;
    }
    
    & .info-box {
        max-width: rem(224);
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: rem(6);        
        & .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            color: $color-black-10;
        }
        & .period {
            font-size: 12px;
            font-weight: 400;
            color: $color-black-40;
        }

        & .badge-box {
            overflow: auto;
            display: flex;
            flex-direction: row;
            gap: rem(10);
        }
    }
    & .status-box {
        width: rem(94);
    }
}