@import '/src/styles/import.scss';

@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url('/assets/font/PretendardVariable.woff2') format('woff2-variations');
}

::-webkit-scrollbar {
  display: block;
  z-index: 999;
  position: absolute;
  width: rem(14);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  opacity: 0;
  height: 136px;
  width: 4px;
  background-color: $color-black-40;
  border-radius: 4px;
  border: 3px solid transparent;
  background-clip: padding-box;
  scrollbar-gutter: stable;
}