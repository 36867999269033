@import 'styles/_import.scss';

.search-input__container {
  display: flex;
  width: 280px;
  height: 40px;
  padding: 4px 12px;
  align-items: center;
  gap: 9px;
  border-radius: 4px;
  border: 1px solid $color-black-60;
  background: $color-black-100;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);

  input.search-input {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    background: transparent;
    border: none;
    outline: none;
    color: #FFF;
  }

  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.clear {
      background: url(/assets/icon/icon-x-circle.svg);
    }

    &.search {
      background: url(/assets/icon/icon-search.svg);
    }
  }
}