@import '/src/styles/_import.scss';

.loading__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  filter: Alpha(Opacity=.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    margin: 30px 0;
    text-align: center;

    .animate-logo {
      width: 98px;
      height: 113px;
      display: inline-block;
      position: relative;
      // background: transparent url('../../assets/images/logos/logo_fixed.png') center no-repeat;
      // background-size: 100%;

      .logo-1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading1.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-1 3s linear infinite;
        animation: fadein-1 3s linear infinite;
      }

      .logo-2 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading2.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-2 3s linear infinite;
        animation: fadein-2 3s linear infinite;
      }

      .logo-3 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading3.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-3 3s linear infinite;
        animation: fadein-3 3s linear infinite;
      }

      .logo-4 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading4.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-4 3s linear infinite;
        animation: fadein-4 3s linear infinite;
      }

      .logo-5 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading5.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-5 3s linear infinite;
        animation: fadein-5 3s linear infinite;
      }

      .logo-6 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent url('/assets/loading/icon_loading6.svg') center no-repeat;
        background-size: 100%;
        -webkit-animation: fadein-6 3s linear infinite;
        animation: fadein-6 3s linear infinite;
      }
    }

    .animate-text {
      padding-top: 24px;
      font-size: 16px;
      color: #fff;
      text-align: center;
    }
  }
}

@keyframes fadein-1 {
  0% {
    opacity: 0;
  }

  13%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-1 {
  0% {
    opacity: 0;
  }

  13%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-1 {
  0% {
    opacity: 0;
  }

  13%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-1 {
  0% {
    opacity: 0;
  }

  13%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes fadein-2 {

  0%,
  15% {
    opacity: 0;
  }

  26%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-2 {

  0%,
  15% {
    opacity: 0;
  }

  26%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-2 {

  0%,
  15% {
    opacity: 0;
  }

  26%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-2 {

  0%,
  15% {
    opacity: 0;
  }

  26%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes fadein-3 {

  0%,
  26% {
    opacity: 0;
  }

  39%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-3 {

  0%,
  26% {
    opacity: 0;
  }

  39%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-3 {

  0%,
  26% {
    opacity: 0;
  }

  39%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-3 {

  0%,
  26% {
    opacity: 0;
  }

  39%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes fadein-4 {

  0%,
  39% {
    opacity: 0;
  }

  52%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-4 {

  0%,
  39% {
    opacity: 0;
  }

  52%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-4 {

  0%,
  39% {
    opacity: 0;
  }

  52%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-4 {

  0%,
  39% {
    opacity: 0;
  }

  52%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes fadein-5 {

  0%,
  52% {
    opacity: 0;
  }

  65%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-5 {

  0%,
  52% {
    opacity: 0;
  }

  65%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-5 {

  0%,
  52% {
    opacity: 0;
  }

  65%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-5 {

  0%,
  52% {
    opacity: 0;
  }

  65%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@keyframes fadein-6 {

  0%,
  65% {
    opacity: 0;
  }

  78%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadein-6 {

  0%,
  65% {
    opacity: 0;
  }

  78%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadein-6 {

  0%,
  65% {
    opacity: 0;
  }

  78%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadein-6 {

  0%,
  65% {
    opacity: 0;
  }

  78%,
  85% {
    opacity: 100;
  }

  95%,
  100% {
    opacity: 0;
  }
}